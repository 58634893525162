@import '~@angular/material/prebuilt-themes/indigo-pink.css';
:root {
  --baseColor: #04e100ad;
  --secondaryColor: #10252C;
  --letfLogo: url(./assets/img/logo_white.png);
  --letfLogoheight: 55px;
  --leftLogoWidth: 207px
  --letfLogoMarginLeft: 10px;
  --letfLogoTextColor: #fff;
  --letfLogoTextMarginTop: -4px;
  --letfLogoTextMarginLeft: 96px;
  --leftLogoToMenuSpace: 30px;
  --sideMenuButtonColor: #646D82;
  --sideMenuWidth: 350px;
  --sideMenuBackgroundImage: url(./assets/img/bg_menu.jpg);
  --sideMenuOptionsTextColor: #fff;
  --sideMenuOptionsTextHoverColor: #fff;
  --sideMenuOptionsBackHoverColor: #04e100ad;
  --sideMenuOptionSelectedTextColor: #fff;
  --sideMenuOptionSelectedBackColor: #04e100ad;
  --sideMenuCopyRightColor:#fff;

  --primaryHeaderBackColor:#fff;
  --primaryHeaderTextColor:#10252C;
  --rightLogo: url(./assets/img/logo.png);
  --defaultUserImage: url(./assets/img/default_user_empusa.png);

  --empusaAppHeaderClassicBackGroup: #10252C;
  --empusaAppHeaderClassicTextColor: #fff;
  --empusaAppHeaderClassicFontFamily: "Segoe UI";
  --empusaAppMaxSize: 77%;
  --secondHeaderToContentSpace: 40px;

  --gbaMh_HelloWorldImage: url('assets/img/home/vms.jpg'),
  --gbaMh_AuthManagerImage: url('assets/img/bg_profile_off.jpg');
  --gbaMh_DashBoardsImage: url('assets/img/bg_dashboard_off.jpg');
}

html,
body {
  font-family: 'Sarabun', sans-serif !important;
  height: 100%;
  margin: 0;
  background-color: var(--background-gray-light, #F4F4F4); ;
  padding: 0px;
}



/***** Cabecera *****/

.head {
  width: calc(100% - 40px);
  padding: 10px 20px 0;
  margin-bottom: 70px;
}

.head mat-icon.menubutton {
  color: var(--sideMenuButtonColor);
  font-size: 30px;
  cursor: pointer;
  user-select: none;
  float: left;
  width: 30px;
  height: 30px;
  margin: 10px 25px 0 0;
}

.head .logo-right {
  background: var(--rightLogo);
  background-repeat: no-repeat;
  background-size:contain;
  width: 308px;
  height: 50px;
  float: left;  --secondHeaderToContentSpace: 40px;

  margin-top: 4px;
  margin-bottom: 4px;
}

.head .default-user-image {
  background: var(--defaultUserImage);
  background-repeat: no-repeat;
  background-size:contain;
  width: 53px;
  height: 50px;
}


.head .morebutton:hover {
  background: #d8d8d86b;
  border-radius: 50%;

}

.head .profile-image {
  cursor: pointer;
  background: var(--defaultUserImage);
  background-size: 111%;
  background-position-x: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-top: 6px;
  margin-left: 10px;
  margin-right: 10px;
}

.head .profile-image-back-ground {
  cursor: pointer;
}


.head .userpicture {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  float: right;
  margin-top: 6px;
  margin-right: 5px;
  animation: fadeIn 2s;
}

.head .userpicture-hidden {
  visibility: hidden;
}

.head .site-one span {
  font-size: 32px;
  font-weight: 600;
  margin: 0 !important;
  line-height: 25px;
  color: var(--primaryHeaderTextColor);
}


/**** end cabecera ****/

/** MENU **/
.head-drawer-title {
  height: var(--leftLogoToMenuSpace);
  margin-top: 30px;
  margin-bottom: 55px;
  padding-left: 20px;
  padding-right: 20px;
}

.head-drawer-title .back {
  position: relative;
  height: 50px;
  float: left;
  cursor: pointer;
  user-select: none;
  color: var(--sideMenuOptionsTextColor);
}

.head-drawer-title .site {
  float: left;
  margin-left: var(--letfLogoTextMarginLeft);
  font-weight: 600;
  margin-top: var(--letfLogoTextMarginTop);
  color: var(--letfLogoTextColor);
  opacity: 0.99;
}

.logo-left {
  height: var(--letfLogoheight);
  background: var(--letfLogo);
  background-size: contain;
  float: left;
  background-repeat: no-repeat;
  opacity: 0.99;
  margin-left: var(--letfLogoMarginLeft);
  width: var(--leftLogoWidth);
}

.side-menu-back {
  content: "";
  background-image: var(--sideMenuBackgroundImage);
  background-size: cover;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  width: var(--sideMenuWidth);
  box-shadow: 7px 0px 4px rgb(0 0 0 / 20%);
}

.side-menu-nav {
  float: left;
  width: 100%;
}

.side-menu-nav ul {
  width: 100%;
  height: 100%;
  display: block;
  margin: 0px;
  padding: 0px;
}

.side-menu-nav ul li {
  width: calc(100% - 20px);
  display: block;
  cursor: pointer;
  user-select: none;
  padding: 10px;
  margin-bottom: 10px;
  outline: none;
  text-transform: capitalize;
}

.side-menu-nav ul li .arrow_box {
  display: none;
}

.side-menu-nav ul li.active .arrow_box {
  display: block;
  width: 20px;
  height: 20px;
  float: right;
  margin-top: -19px;
  color: #fff;
  margin-right: -10px;
  font-size: 20px;
  line-height: 20px;
  font-weight: bold;
  color: var(--sideMenuOptionSelectedTextColor);
}

.side-menu-options {
  display: block;
  width: calc(100% - 40px);
  height: 20px;
  color: var(--sideMenuOptionsTextColor);
}

nav ul li:hover {
  background: var(--sideMenuOptionsBackHoverColor);
}

nav ul li:hover a {
  color: var(--sideMenuOptionsTextHoverColor);
}

nav ul li.active {
  background: var(--sideMenuOptionSelectedBackColor);
}

nav ul li.active a {
  color: var(--sideMenuOptionSelectedTextColor);
}

nav ul li a mat-icon {
  float: left;
  margin-right: 10px;
}

nav ul li a span {
  display: block;
  float: left;
  line-height: 24px;
  max-width: 145px;
  overflow: hidden;
  white-space: nowrap;
}

/** MENU ENd**/

/***** Menu language *****/
.language-menu button.selected {
  background: #eaeaea;
}

.mat-tooltip {
  font-size: 14px !important;
}

/* ----------------------------------------------------------------------- */


/********** Estilos micro apps ******/
.empusa-microapp-fondo{
  margin-top: var(--secondHeaderToContentSpace);
  margin-bottom: 20px;
  margin-right: 20px;
  margin-left: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.24), 0 2px 5px 0 rgba(0, 0, 0, 0.12);
  border-style: solid;
  border-width: 1px;
  border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.4) 5%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0));
  border-image-slice: 1;
  background-image: linear-gradient(to bottom, #ffffff, #ffffff), linear-gradient(to bottom, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.4) 5%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0));
  background-origin: border-box;
  background-clip: content-box, border-box;
  //max-height: var(--empusaAppMaxSize)
}

/********** Estilos micro apps ******/

.head-right {
  display:inline-flex;
  margin-top: 3px;
  margin-left: 6px;
  background-color: transparent;
}


.menuTrigger {
  margin-top: 15px;
  cursor: pointer;
  color: var(--sideMenuButtonColor);
}



/*** HOMEPAGE ****/
.container {
  width: 100%;
  margin: 0px;
  border: none;
  overflow: visible !important;
}

@media (min-width: 600px) {
.container {
height: 100%;

}

// .container-form{
//   height: 70%;
// }
}

mat-drawer .divider {
  width: 100%;
  height: 1px;
  background: var(--sideMenuCopyRightColor);
  bottom: 50px;
  position: absolute;
  margin: auto;
}

mat-drawer .copyright:before {
  content: "";
}

mat-drawer .copyright {
  display: block;
  position: absolute;
  bottom: 15px;
  color: var(--sideMenuCopyRightColor);
  left: 15px;
  width: 90%;
  font-weight: 600;
}

mat-drawer .terms-and-privacy {
  position: absolute;
  bottom: 55px;
  color: var(--sideMenuCopyRightColor);
  width: 100%;
  text-align: left
}

.empusa-container {
  display: block;
  background: #E4E4E4;
}

/**  ATTENTION! PART OF THE CSS STYLE FOR THIS COMBO IS ON THE GENERIC STYLE.CSS */
.container .primary-head {
  background-color: var(--primaryHeaderBackColor);
  margin-bottom: 14px;
}
.head .site-selection {
  float: right;
  color: white !important;
  margin-right: 80px;
  margin-top: -11px;
}

.head .site-one {
    margin-top: 0;
    font-size: 14px;
    font-weight: 400;
    margin-right: 15px;
    color: var(--primaryHeaderTextColor);

}


.head .site-one span {
  display: block;
  float: right;
  margin-top: 3px;
  margin-left: 6px;
  color: var(--primaryHeaderTextColor);
}

.head-right {
  float:right;
  margin-top: 3px;
  margin-left: 6px;
  color: #646D82 !important;
}

.head-right .currentuser {
  float: right;
  margin-right: 30px;
}

.head-right .currentuser mat-icon {
  display: block;
  float: left;
  margin-right: 2px;
  margin-top: -1px;
}

.head-right a {
  float: right;
  margin-right: 10px;

  font-size: 18px;
  font-weight: bold;
  margin-top: -2px;
  cursor: pointer;
  user-select: none;
}

.head-right mat-select {
  background: white;
  max-width: 200px;
  float: right;
  margin-right: 15px;
  border-radius: 3px;
  color: black;
}

.head-right a:hover {
  text-decoration: underline;
}

/** anchos column table **/
.w-50 {max-width: 50px;}
.w-80 {max-width: 80px;}
.w-100 {max-width: 100px;}
.w-150 {max-width: 150px;}
.w-180 {max-width: 180px;}

.th-center {text-align: center;}
.th-left {text-align: left;}
.th-right {text-align: right;}

@import "~@ng-select/ng-select/themes/default.theme.css";

// Altura de 40px en los mat-form-fields appearance outline
.mdc-text-field--outlined {
  height: 40px!important;
  align-items: center!important;
}

// Añadir clase textarea-field a los textarea para que su altura sea dinámica 
// (no de 40px como el resto de mat-form-fields)
.textarea-field .mdc-text-field--outlined {
  height: auto!important;
  align-items: center!important;
}
textarea {
  resize: none !important;
}
.form-control:focus {
  border-color: none!important;
  box-shadow: none!important; 
}

//sweetalert botón de color azul
.swal2-styled.swal2-confirm {
  background-color: #0F56A6 !important;
}

.swal2-styled {
  transition: none !important;
  box-shadow: none !important;
}
//Snackbar
.mat-mdc-snack-bar-container .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
  font-size: 20px;
  color:#1d252c!important;
}
.error-snackbar {
  .mdc-snackbar__surface {
    background: #f8d7da !important;
  }
  .mat-mdc-snack-bar-label {
    color: #721c24 !important;
  }
  //snackbar

}

.success-snackbar {
  .mdc-snackbar__surface {
    background-color: #d4edda !important;
  }
  .mat-mdc-snack-bar-label {
    color: #155724 !important;
  }
}

.warning-snackbar {
  .mdc-snackbar__surface {
    background-color: #fff3cd !important;
  }
  .mat-mdc-snack-bar-label {
    color: #856404 !important;
  }
}

.info-snackbar {
  .mdc-snackbar__surface {
    background-color: var(--mdc-snackbar-container-color);
  }
  .mat-mdc-snack-bar-label {
    color: var(--mdc-snackbar-supporting-text-color);
  }
}

lib-ge-table .row {
  margin: 0px !important;
}

lib-ge-table .row .col-md-12{
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.mensaje-container {
	text-align: center;
	margin-top: 40px;
	height: 132px;
  }

  .mensaje-linea {
	margin: 6px 0;
	font-style: italic;
	color: #808080;
  }

  .container-form{
    background-color: #f4f4f4;
    max-width: 100%;
    padding: 0 33px;
  }

  .mensaje-container{
    justify-content: center;
  }


.bg-second-migas{
  background-color: #E4E4E4;
  width: 100%;
  display: flex;
  height: auto;
  z-index: 600;
  padding: 20px 0px 20px 0;
}

.bg-second-table-info{
  background-color: #E4E4E4;
  width: 100%;
  display: flex;
  height: auto;
  z-index: 600;
  padding: 20px 0px 20px 0;
  justify-content: center;
}

.table-detail-width-100{
  width: 100%;
}



.mat-drawer-content{
  height: auto !important;
}

.side-menu-back {
  position: fixed;
  top: 0;
  bottom: 0;
}


.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}


.empusa-container {
  margin-top: 127px;
  margin-bottom: 39px;
  overflow-y: auto;
  height: calc(97vh - 150px);
}

.my-flo{
  padding-bottom: 12px;
}

.bodyMicroApp{
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  margin: 0;
  font-family: var(--font-family-body);
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: var(--dark, #1A1A1A);
  text-align: left;
  background-color: var(--background-gray-light, #F4F4F4);
}


// CSS lib-ge-select-pagination
.ng-select .ng-select-container {
  height: 40px !important;
}

:host ::ng-deep .ng-dropdown-panel.ng-select-top{
    bottom: auto !important;
}

ng-dropdown-panel .ng-dropdown-panel-items .ng-option{
    font-size: 16px;
}

.ng-select .ng-select-container {
  min-height: 32px;
}
.ng-select .ng-select-container .ng-value-container .ng-input>input{
  height: 32px !important;
}

.input-group {
  min-height: 32px;
}


.mat-mdc-select-placeholder{
  font-family: 'Sarabun', sans-serif !important;
  color: #c6c6c6 !important;
}


.row-fields {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  label {
      display: block;
  }
  .field {
      display: flex;
      flex-direction: column;
  }
  .field-50 {
      width: 49%;
  }
  .field-33 {
      width: 32%;
  }
  .field-66 {
      width: 65.5%;
  }
  .field-25 {
      width: 24%;
  }
  .field-75 {
      width: 74%;
  }
  .field-100 {
      width: 100%;
  }
}
.mat-mdc-form-field-error {
  font-size: 12px;
  font-family: "Roboto";
}
