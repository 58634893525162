@font-face {
  font-family: 'icons-envanet';
  src:  url('../../font-icons/icons-envanet.eot?dryjwl');
  src:  url('../../font-icons/icons-envanet.eot?dryjwl#iefix') format('embedded-opentype'),
    url('../../font-icons/icons-envanet.ttf?dryjwl') format('truetype'),
    url('../../font-icons/icons-envanet.woff?dryjwl') format('woff'),
    url('../../font-icons/icons-envanet.svg?dryjwl#icons-envanet') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icons-envanet' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-add:before {
  content: "\e904";
  color: #2060be;
}
.icon-arrow-end:before {
  content: "\e905";
  color: #2060be;
}
.icon-arrow-left:before {
  content: "\e906";
  color: #2060be;
}
.icon-arrow-right:before {
  content: "\e907";
  color: #2060be;
}
.icon-arrow-start:before {
  content: "\e908";
  color: #2060be;
}
.icon-arrow-up:before {
  content: "\e909";
  color: #2060be;
}
.icon-mas-info:before {
  content: "\e90a";
  color: #004a9a;
}
.icon-arrow-down:before {
  content: "\e900";
  color: #2060be;
}
.icon-editar:before {
  content: "\e901";
  color: #2060be;
}
.icon-eliminar:before {
  content: "\e902";
  color: #2060be;
}
.icon-user:before {
  content: "\e903";
  color: #5fc4e1;
}
