@charset "UTF-8";

/* Tipografía Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400;1,500&display=swap');

/* Tipografía Icons */
@import './fonts/icons.css';

/* -----------------------------------
    Estilos generales
------------------------------------
:root {
  --blue: #004A9A;
  --purple: #773E91;
  --pink: #B90E60;
  --red: #dc3545;
  --orange: #F59E33;
  --green: #87BD25;
  --cyan: #5FC4E1;
  --white: #fff;
  --gray: #A8A7A7;
  --gray-dark: #1A1A1A;
  --primary: #004A9A;
  --secondary: #A8A7A7;
  --success: #87BD25;
  --info: #5FC4E1;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #F4F4F4;
  --dark: #1A1A1A;
  --background-gray-light: #F4F4F4;
  --background-gray-med: #E4E4E4;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 768px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --breakpoint-xxl: 1400px;
  --font-family-body: 'Sarabun', sans-serif;
} */


*, *:before, *:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -ms-flex: 0 1 auto;
}

/* html {
  font-family: sans-serif;
  line-height: 1.15;
  box-sizing: border-box;
} */

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

/*
body {
  margin: 0;
  font-family: var(--font-family-body);
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.5;
  color: var(--dark, #1A1A1A);
  text-align: left;
  background-color: var(--background-gray-light, #F4F4F4);
} */

/* --------------------------------------------- */

body{
  background-color:#F4F4F4
}


a {
  font-family: var(--font-family-body);
  color: var(--primary, #004A9A);
  text-decoration: none;
  background-color: transparent;
  font-weight: 400;
  margin: 0px;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

a:focus {
	outline: none !important;
}
a:hover {
  color: var(--green, #87BD25);
  text-decoration: none;
}

/*---------------------------------------------*/

h1 {
margin: auto;
}

p {
	font-family: var(--font-family-body);
	line-height: 1.5;
	font-size: 1rem;
	color: #1D252C;
	margin: 0px;
	font-weight: 400;
}

ul, li {
	margin: 0px;
	list-style-type: none;
}

/*---------------------------------------------*/


.text-primary {
	color: var(--primary, #004A9A) !important;
}

.text-green {
	color: var(--green, #87BD25);
}

a.text-primary:hover, a.text-primary:focus {
  color: #03C000 !important; }


.text-secondary {
  color: #5B6670 !important;
}

ul a {
  color: #333;
  text-decoration: none;
  background-color: transparent;
  font-weight: 400;
}

ul a:hover {
  text-decoration: none;
}


.btn {
  font-family: var(--font-family-body);
  font-weight: 400;
  font-size: 0.95rem;
  border-radius: 0.2rem;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  height: 40px;
  border: 0rem;

}

button.btn-primary span:before {
	color: var(--white, #fff);
	font-size: 16px;
}

.btn:active:focus, .btn.focus, .btn.active, .btn .btn-primary:active:focus, .btn .btn-primary.focus, .btn .btn-primary.active, .btn .btn-secondary:active:focus, .btn .btn-secondary.active, .btn .btn-secondary.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(95, 196, 225, 0.25);
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus, .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(95, 196, 225, 0.25);
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(95, 196, 225, 0.25);
}

.btn-primary {
  display:flex;
  gap:10px;
  align-items: center;
  background: linear-gradient(to bottom, #1262A8, #004A9A);
  color: var(--white, #fff);
  border: 0rem;
}

.btn-primary:hover {
  color: var(--white, #fff);
  background: linear-gradient(to bottom, #A8DD48, #87BD25);
  border: 0rem;
}

.btn-outline-primary {
    border: 1px solid rgba(0, 74, 154, 0.5);
    background-color: var(--white, #fff);
    font-weight: bold;
    color: #004A9A;
}

.btn-outline-primary:hover {
  	color: var(--green, #87BD25);
    border: 1px solid var(--green, #87BD25);
    background-color: var(--white, #fff);
}

.btn-primary:focus, .btn-primary.focus {
  color: var(--white, #fff);
  background: linear-gradient(to bottom, #A8DD48, #87BD25);
  border: 0rem;
box-shadow: 0 0 0 0.2rem rgba(135, 189, 37, 0.2);
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  color: var(--green, #87BD25);
  background-color: var(--white, #fff);
  border-color: var(--green, #87BD25);
  box-shadow: 0 0 0 0.2rem rgba(135, 189, 37, 0.2);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #999;
  border-color: #999;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  background: linear-gradient(to bottom, #1262A8, #004A9A);
  color: var(--white, #fff);
  border: 0rem;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(95, 196, 225, 0.25);
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active, .show > .btn-outline-primary.dropdown-toggle {
  color: var(--green, #87BD25);
  background-color: transparent;
  border-color: var(--green, #87BD25);
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(135, 189, 37, 0.2);
}


.btn-secondary {
  background: linear-gradient(to bottom, #5FC4E1, #08A3D0);
  color: var(--white, #fff);
  border: 0rem;
}

.btn-secondary:hover {
  color: var(--white, #fff);
  background: linear-gradient(to bottom, #A8DD48, #87BD25);
  border: 0rem;
}


.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 10px;
    padding-left: 10px;
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -10px;
    margin-left: -10px;
}

.row.form {
    display: flex;
	row-gap: 0.3rem;
}



/* -----------------------------------
   CABECERA FIXED
------------------------------------*/

body.fixed-header .header {
  position: fixed;
  left: 0;
  top: 0;
  user-select: none;
}

.horizontal-apli-menu.fixed-header {
    overflow: hidden;
}

.horizontal-menu {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

.horizontal-apli-menu.fixed-header .header {
    position: relative;
}

.horizontal-apli-menu .header {
    display: block;
    height: auto;
    border-bottom: none;
    background-color: var(--primary, #004A9A);
    background: linear-gradient(to bottom, #1262A8, #004A9A);
    color: #929aac;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
	position: fixed;
}

.header {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 60px;
    width: 100%;
    padding: 0 0 0 0;
    z-index: 800;
    background-color: #fff;
    border-bottom: 1px solid rgba(224, 224, 224, 0.7);
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.horizontal-apli-menu .header .header-md-height {
    height: 51px;
}

.horizontal-apli-menu .header .header-inner {
    height: 64px;
    background-color: transparent;
    position: relative;
	padding: 20px 42px 20px 33px;
}

.header .header-inner {
    height: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.horizontal-apli-menu .header .container {
    width: 100%;
	max-width: 100%;
	margin: 0 auto;
    padding-right: 33px;
    padding-left: 33px;
}

.header .header-inner .logo-name {
	display: flex;
	flex-direction: row;
	gap:16px;
	align-items: baseline;
}

.header .header-inner .logo-name span.line-vert {
	display: inline-flex;
    width: 1px;
    height: 24px;
    background: rgba(255, 255, 255, 0.66);
}

.header .header-inner .logo-name div.name-apli {
	display: inline-flex;
	font-size: 1.1rem;
	letter-spacing: 2.5px;
	font-weight: 700 !important;
	color: #94CE2A;
}

.header-inner .nav .nav-item.dropdown .nav-link .icon-user {
	font-size: 27px;
	vertical-align: bottom;
}

.header-inner .nav .nav-item .dropdown-menu-user span.icon-user {
	font-size: 48px;
}

.header-inner .nav .nav-item .dropdown-menu-user div.datos-user {
	font-size: 1rem;
	font-weight: 600;
	margin: 0.25rem 0rem 1rem 0rem;
}

.navbar-nav .nav-item .dropdown-menu .dropdown-item-title {
	font-size: 14px;
	font-weight: 600;
	padding: 0.5rem 1.5rem 0.8rem 1.5rem;
}

/*  Dropdowns */

 .dropdown-menu {
    position: absolute;
    top: 0;
    left: -0.5rem !important;
    z-index: 1000;
    float: left;
    padding: 0.8rem 0rem;
    margin: 0;
    font-size: 0.8rem;
    color: #1d252c;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.3rem;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
}

 .dropdown-menu-user {
    position: absolute;
	width: 15rem;
    top: -1.75rem !important;
    left: -23px !important;
    z-index: 1000;
    float: left;
    padding: 1rem 1rem;
    margin: 0rem !important;
    font-size: 0.9rem;
    color: #1d252c;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.3rem;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
}

 .dropdown-menu-gris {
    position: absolute;
    top: 100%;
    left: 0.3rem !important;
    z-index: 1000;
    display: none;
    float: left;
    padding: 1rem 0rem;
    margin: 0.125rem 0 0;
    font-size: 0.85rem;
    color: #1d252c;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px solid rgba(0, 0, 0, 0.15);
    border-radius: 0rem;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: 0.6rem 1.5rem;
    clear: both;
    font-weight: 400 !important;
    color: #1d252c;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
	line-height: 100% !important;
}
.navbar ul li a.dropdown-item {
	color: var(--dark, #1A1A1A);
}

.navbar ul li a.dropdown-item:hover {
	color: var(--green, #87BD25);
}

.dropdown-item:hover, .dropdown-item:focus {
	color: var(--green, #87BD25);
    text-decoration: none;
    /*background-color: #f8f9fa;*/
	background-color: rgba(135, 189, 37, 0);
}

.dropdown-toggle-cols::after {
    display: inline-block;
    content: '';
    position: absolute;
    margin-left: -0.5em;
    vertical-align: 0.255em;
    top: 0.7rem;
    background: url("../images/f-dropdown.svg") no-repeat;
    width: 25%;
    height: 25%;
    border-top: 0em solid;
    border-right: 0em solid transparent;
    border-bottom: 0;
    border-left: 0em solid transparent;
}

.dropdown-menu-tabla.show {
    display: block;
}
.dropdown-menu-tabla[x-placement^="top"], .dropdown-menu-tabla[x-placement^="right"], .dropdown-menu-tabla[x-placement^="bottom"], .dropdown-menu-tabla[x-placement^="left"] {
    right: auto;
    bottom: auto;
}
.dropdown-menu-tabla {
    position: absolute;
    top: 100%;
/*    left: -0.3rem !important;*/
    z-index: 1000;
    display: none;
    float: left;
    padding: 0.8rem 0.4rem;
    margin: 0.125rem 0 0;
    font-size: 0.85rem;
    color: var(--dark, #1a1a1a);
    text-align: left;
    list-style: none;
    background-color: var(--primary, #004A9A);
    background-clip: padding-box;
    border: 0px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.2rem;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
	min-width: auto;
}

.dropdown-menu-export {
    position: absolute;
    top: 100%;
/*    left: -0.3rem !important;*/
    z-index: 1000;
    display: none;
    float: left;
    padding: 0.8rem 0.4rem;
    margin: 0.125rem 0 0;
    font-size: 0.85rem;
    color: var(--dark, #1a1a1a);
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px solid rgba(0, 0, 0, 0.15);
    border-radius: 0.2rem;
    box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
	min-width: auto;
}

.dropdown-item-tabla {
    display: block;
    width: 100%;
    padding: 0.5rem 1.2rem;
    clear: both;
    font-weight: 400;
    color: var(--white, #fff);
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dropdown-item-tabla:hover, .dropdown-item-tabla:focus {
    color: var(--green, #87BD25);
    text-decoration: none;
}

/* -----------------------------------
   FIN CABECERA FIXED
------------------------------------*/




/* ---  Margin top para container con cabecera fija  ---*/

.container.mt-cab-fixed {
    margin-top: 130px;
}


/* ---  Containers (max-width:1366px y fluid  ---*/

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    width: 100%;
    padding-right: 33px;
    padding-left: 33px;
}




/* Menú de navegación principal (plegable/desplegable) */

.navbar {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
	padding: 0.2rem 0rem 0.2rem 0rem;
}

.navbar-nav.navbar-center {
    position: absolute;
    left: 50%;
    transform: translatex(-50%);
}

.navbar-toggler {
    padding: 0.5rem 0.7rem 0.5rem 0rem;
    font-size: 1.2rem;
    line-height: 1;
    background-color: transparent;
    border: 0px solid #fff;
    border-radius: 0rem;
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
}

.custom-toggler .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.custom-toggler.navbar-toggler {
 border-color: #fff;
}

button:focus {
    outline: 0px dotted;
    outline: 0px auto -webkit-focus-ring-color;
}

.navbar ul{
    font-size: 14px;
	text-transform: uppercase;
	font-weight: 500;
}

.navbar ul li.active{
	border-bottom: 3px solid #03e100;
}

.navbar ul li a{
	line-height: 300%;
	font-weight: 500;
	color: var(--white, #fff);
}

.navbar ul li a:hover{
	text-decoration: none;
	color: rgba(255, 255, 255, 0.6);
}



/* -- Dropdown usuario registrado --*/

.dropdown-toggle::after {
	display: inline-block;
	content: '';
    position: absolute;
	margin-left: 0.25em;
    vertical-align: 0.25em;
	top:1.7rem;
    background: url(../images/f-dropdown-blanco.svg) no-repeat;
    width: 7.5%;
    height: 7.5%;
	border-top: 0em solid;
    border-right: 0em solid transparent;
    border-bottom: 0;
    border-left: 0em solid transparent;
}

.dropdown-toggle-user::after {
	display: inline-block;
	content: '';
    position: absolute;
	margin-left: 0.2em;
	top:0.8rem;
    background: url(../images/f-dropdown-blanco.svg) no-repeat;
    width: 30%;
    height: 30%;
	border-top: 0em solid;
    border-right: 0em solid transparent;
    border-bottom: 0;
    border-left: 0em solid transparent;
}


/* -------------------------------------------
    Migas de pan y Título de página
--------------------------------------------*/

.breadcrumb {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0rem;
    margin-bottom: 0rem;
    list-style: none;
    background-color: transparent;
    border-radius: 0rem;
	font-size: 14px;
}

/* .breadcrumb-item.active {
    color: var(--dark, #1A1A1A);
	font-weight: 400;
} */

.breadcrumb-item + .breadcrumb-item::before {
    display: inline-block;
padding-right: 0.5rem;
    color: var(--dark, #1A1A1A);
    content: ">";
	  font-size:11px;
    padding-top: 3px;
}


h2.title-pag {
    display: flex;
	font-size:1.5rem;
    padding-right: 0.5rem;
    color: var(--primary, #004A9A);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}


/* -----------------------------------
    Card que contiene la tabla
------------------------------------*/

.card-tabla-proy {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.4rem;
}

.card-tabla-proy div.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    min-height: 1px;
    padding: 2rem;
}


/* TABLA */

.table {
    background-color: #fff;
	margin-bottom: 0rem;
}

.table thead th {
    background-color: #fff;
    vertical-align: middle;
    border-bottom: 1px solid #A8A7A7;
    border-top: 0px;
}

.table thead th.noborderb {
    border-bottom: none;
}

.table th, .table td {
    padding: 0.7rem 1rem 0.7rem 1rem;
    vertical-align: top;
    border-top: 1px solid #E0E0E0;
	border-bottom: 1px solid #E0E0E0;
    font-size: 1rem;
    color: #1d252c;
    font-weight: 400;
	line-height: 1.5;
}

.table thead tr th{
   background-color: #fff;
   text-transform: uppercase;
   font-size: 0.85rem;
   font-weight: 600;
   color: var(--primary, #004A9A);
}

.table tbody tr td{
	line-height: 0.3;
	vertical-align: middle;
}

.table tbody tr td div.actions{
	display: flex;
	align-items: center;
	gap:6px;
	width: auto;
	justify-content: space-between;
}

.table tbody tr td div.actions span{
	font-size: 21px;
}
.table tbody tr td div.actions span.icon-mas-info{
	font-size: 14px;
}

.table-hover tbody tr:hover {
    color: #212529;
    background-color: rgba(135, 189, 37, 0.1);
	cursor: pointer;
}

.table tbody tr td span.line-clamp-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  -webkit-box-orient: vertical;
}

.actions-table {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem 0;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(230, 230, 230, 1);
}

table.dataTable thead .sorting,table.dataTable thead .sorting_asc,table.dataTable thead .sorting_desc,table.dataTable thead .sorting_asc_disabled,table.dataTable thead .sorting_desc_disabled{
    cursor:pointer;
    position:relative;
}

.table-active, .table-active > th, .table-active > td {
    background-color: rgba(3, 225, 0, 0.2);
}

table.dataTable thead .sorting:before, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_desc_disabled:before {
    right: 0.8rem;
    content: "\2191";
	font-size: 16px;
	color: var(--gray, #A8A7A7);
}

table.dataTable thead .sorting:before, table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:before, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:before, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:before, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:before, table.dataTable thead .sorting_desc_disabled:after {
    position: absolute;
    top: 50%;
    margin-top: -12px;
    display: block;
    opacity: 1;
}

table.dataTable thead .sorting:after, table.dataTable thead .sorting_asc:after, table.dataTable thead .sorting_desc:after, table.dataTable thead .sorting_asc_disabled:after, table.dataTable thead .sorting_desc_disabled:after {
    right: 0.5rem;
    content: "\2193";
	font-size: 16px;
	color: var(--gray, #1A1A1A);
}

table.dataTable thead>tr>th.sorting_asc,table.dataTable thead>tr>th.sorting_desc,table.dataTable thead>tr>th.sorting,table.dataTable thead>tr>td.sorting_asc,table.dataTable thead>tr>td.sorting_desc,table.dataTable thead>tr>td.sorting{
    padding-right:20px
}

table.dataTable thead>tr>th:active,table.dataTable thead>tr>td:active{
    outline:none
}

table.dataTable thead .sorting,table.dataTable thead .sorting_asc,table.dataTable thead .sorting_desc,table.dataTable thead .sorting_asc_disabled,table.dataTable thead .sorting_desc_disabled{
    cursor:pointer;
    position:relative
}

/* FIN TABLA */


/* Botón Dropdown Tabla */

.btn-tabla {
    display:flex;
	align-items: center;
	background-color: #F4F4F4;
	width: 30px;
	height: 30px;
	justify-content: center;
	border-radius: 0.2rem;
}

.btn-drop-tabla:focus, .btn.focus {
    outline: 0;
    box-shadow: 0 0 0 0rem rgba(0, 123, 255, 0.25);
	background-color: #fff;
	border-radius: 0.2rem;
	border: 1px solid rgba(0, 74, 154, 0.5);
}

.dropdown-toggle-tabla::after {
    display: none;
    content: '';
    position: absolute;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    top: 1.2rem;
    background: url(../images/f-dropdown.svg) no-repeat;
    width: 17%;
    height: 17%;
    border-top: 0em solid;
    border-right: 0em solid transparent;
    border-bottom: 0;
    border-left: 0em solid transparent;
}


/* Ventanas Modal*/

.modal-content {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.1rem;
    outline: 0;
}

.modal-header {
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem 2rem 0.5rem 2rem !important;
    border-bottom: 0px solid #e9ecef;
    border-top-left-radius: 0.1rem;
    border-top-right-radius: 0.1rem;
}

.modal-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: center;
    padding: 1rem;
    border-top: 0px solid #e9ecef;
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem 2rem 1rem 2rem;
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5;
	font-size: 1.6rem;
	color: var(--primary, #004A9A);
	margin: 0.5rem 0 0 0rem;
	font-weight: 600;
}

.modal-title span{
	font-size: 1rem;
	font-weight: 400;
	font-style: italic;
}

.modal-body .form-modal .title-group {
	display: flex;
	text-transform: uppercase;
	font-size: 0.9rem;
	color: var(--primary, #004A9A);
	font-weight: 500;
}


.modal-footer.actions .note {
	font-size: 0.8rem;
	color: #E21E20;
}

span.oblig {
	color: #E21E20;
	font-weight: 300;
}

.close {
    float: right;
    font-size: 2.2rem;
    font-weight: lighter;
    line-height: 1;
    color: #999;
    text-shadow: 0 0px 0 #fff;
    opacity: 1;
    background-color: transparent;
    border: 0;
}

.divider {
	border-left: 1px solid #BEBEBE;
}


.text-gris {
	color: #666;
}

.label-info {
	font-size: 0.8rem;
	color: #5B6670;
	border-bottom: 1px solid #CFD2D3;
}

.dato-info {
	font-size: 0.9rem;
	width: 60%;
	border-bottom: 1px solid #CFD2D3;
}




/* Elementos de Formularios */

.form-control {
    display: block;
    width: 100%;
    height: 40px;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5;
    color: var(--dark, #1A1A1A);
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control.error {
    background-color: rgba(226,30,32,.03);
    border: 1px solid rgba(226,30,32,.75);
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}


input:focus::-webkit-input-placeholder { color:transparent; }
input:focus:-moz-placeholder { color:transparent; }
input:focus::-moz-placeholder { color:transparent; }
input:focus:-ms-input-placeholder { color:transparent; }

textarea:focus::-webkit-input-placeholder { color:transparent; }
textarea:focus:-moz-placeholder { color:transparent; }
textarea:focus::-moz-placeholder { color:transparent; }
textarea:focus:-ms-input-placeholder { color:transparent; }

input::-webkit-input-placeholder { color: #C6C6C6!important; font-weight: 300;}
input:-moz-placeholder { color: #C6C6C6!important; font-weight: 300;}
input::-moz-placeholder { color: #C6C6C6!important; font-weight: 300;}
input:-ms-input-placeholder { color: #C6C6C6!important; font-weight: 300;}

textarea::-webkit-input-placeholder { color: #C6C6C6!important; font-weight: 300;}
textarea:-moz-placeholder { color: #C6C6C6!important; font-weight: 300;}
textarea::-moz-placeholder { color: #C6C6C6!important; font-weight: 300;}
textarea:-ms-input-placeholder { color: #C6C6C6!important; font-weight: 300;}


label {
    display: inline-block;
    margin-bottom: 0.2rem;
	font-weight: 300;
	font-size:0.9rem;
	color: #8D8D8D;
}

.col-form-label {
    margin: 0 0;
    font-size: 0.8rem;
    line-height: 1.5;
}

.form-row > .col, .form-row > [class*="col-"] {
    padding-right: 9px;
    padding-left: 9px;
}

i.disabled {
    color: #BFBFBF;
}
.caja-dest {
	border: 1px solid #ccc;
	min-height: 115px;
	border-radius: 0.25rem;
}


.form-check-input:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e")
}

.form-check-input:checked[type=radio] {
    background-color: #007435;
    border-color: #007435;
}

.form-check-input[type=radio] {
    border-radius: 50%;
}

.form-check .form-check-input {
    float: left;
    margin-left: -1.2rem;
}

.form-check-input[type=radio] {
    width: 1rem;
    height: 1rem;
    margin-top: 0.25rem;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0,0,0,.25);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-print-color-adjust: exact;
    print-color-adjust: exact;
}

.form-check-inline .form-check-input[type=radio] {
    position: static;
    margin-top: 0;
    margin-right: 0.6rem;
    margin-left: 0;
}

.form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: -1.25rem;
    margin-right: 1.5rem;
}


.form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23FFFFFF' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}

.form-check-input:checked[type=checkbox] {
    background-color: #7B868C;
    border-color: #7B868C;
}
.form-check-input[type=checkbox] {
    border-radius: 0.2rem;
}

.form-check .form-check-input[type=checkbox] {
    float: left;
    margin-left: -1.2rem;
}

.form-check-input[type=checkbox] {
    width: 21px;
    height: 21px;
    margin-top: 0.25rem;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid #666;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-print-color-adjust: exact;
    print-color-adjust: exact;
}

.form-check-inline .form-check-input[type=checkbox] {
    position: static;
    margin-top: 0;
    margin-right: 0.6rem;
    margin-left: 0;
}


.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #63b42d;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #63b42d;
}


.input-group-icon {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: 0.275rem 0.375rem;
    margin-bottom: 0;
    font-size: 0.6rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    border-right: 1px solid #A2A9AD;
	border-left: 0px solid #A2A9AD;
	border-top: 1px solid #A2A9AD;
	border-bottom: 1px solid #A2A9AD;
    border-radius: 0rem;
}

.input-group-icon span{
    font-size: 1.2rem;
    font-weight: 400;
}

.select {
	padding: 0.375rem 0.75rem;
    text-overflow: ellipsis;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIGlkPSJDYXBhXzIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDI4LjgyODEyIDE3LjI0MjE5Ij48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6IzFhMWExYTtzdHJva2Utd2lkdGg6MHB4O308L3N0eWxlPjwvZGVmcz48ZyBpZD0iSWNvbm9ncmFmw61hXzMyeDMyIj48ZyBpZD0iYXJyb3ctZG93bl90cmF6YWRvXyI+PHBvbHlnb24gY2xhc3M9ImNscy0xIiBwb2ludHM9IjE0LjQxNDA2IDE3LjI0MjE5IDAgMi44MjgxMiAyLjgyODEyIDAgMTQuNDE0MDYgMTEuNTg1OTQgMjYgMCAyOC44MjgxMiAyLjgyODEyIDE0LjQxNDA2IDE3LjI0MjE5Ii8+PC9nPjwvZz48L3N2Zz4=);
    background-repeat: no-repeat, repeat;
    background-position: right 12px top 50%, 0 0;
    background-size: 0.65em auto, 100%;
    overflow: hidden;
	min-width: 64px;
}

/* radio-buttons */

[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
	top:-0.5rem;
    padding-left: 0.9rem;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 21px;
    height: 21px;
    border: 1px solid #666;
    border-radius: 100%;
    background: #fff;
}

[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 0.8rem;
    height: 0.8rem;
    background: #039200;
    position: absolute;
    top: 4px;
    left: 4px;
	border: 1px solid #fff;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}


/* Fondo de color gris */

.bg-second {
	background-color: #E4E4E4;
	width: 100%;
	display: flex;
	height: auto;
	z-index: 600;
	padding: 20px 0px 20px 0;
}


/* Zona anterior al contenido que contiene las migas de pan y el título de la página */

.pre-content {
	display: flex;
	flex-direction: column;
	gap: 20px;
}

.pre-content .content-title {
	display: flex;
	justify-content: space-between;
	align-items: center;
}


/* Formulario de Búsqueda */

.form-search, .form-modal {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
.form-search .button-group {
	display: flex;
	gap: 20px;
	justify-content: center;
	padding: 1rem 0 0.5rem 0;
}

.modal-footer.actions {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
	border-top: 1px solid #A8A7A7;
	padding: 1.2rem 2rem 1.2rem 2rem;
}

.modal-footer.actions .button-group {
	display: flex;
	align-items: center;
	gap: 20px;
	justify-content: center;
}


/* -- PAGINACIÓN --*/

.page-link {
    position: relative;
    display: block;
    padding: 0.4rem 0.7rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #A8A7A7;
    background-color: #fff;
    border: none;
    font-size: 14px;
}

.page-link span.icon-arrow-start:before, .page-link span.icon-arrow-end:before, .page-link span.icon-arrow-left:before, .page-link span.icon-arrow-right:before {
    font-size: 12px;
    color: var(--primary, #004A9A);
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: var(--primary, #004A9A);
    border: none;
	border-radius: 0.2rem;
    font-size: 14px;
}



/* -- FOOTER --*/

.footer .text-footer {
	color: #808080;
	font-size: 0.9rem;
	font-weight: 300;
  padding-left: 15px;
}








/* -- BREAKPOINTS --*/

@media (min-width: 0px) and (max-width: 575px) {

  .empusa-container {
    margin-top: 60px;
    margin-bottom: 50px;
    overflow-y: auto;
    height: calc(94vh - 90px);
  }

.horizontal-apli-menu .header .header-inner {
    height: 51px;
	padding: 16px 25px 16px 15px;
}

.header .header-inner .logo-name {
	gap: 12px;
}

.header .header-inner .logo-name img {
	height: 20px;
}

.header .header-inner .logo-name span.line-vert {
    height: 20px;
}

.header .header-inner .logo-name div.name-apli {
	font-size: 0.9rem;
	letter-spacing: 1px;
}
nav.navbar .navbar-collapse {
	padding: 0.8rem 0rem 2.6rem 0rem;
}

.navbar-expand-xl .navbar-nav .nav-link {
	padding: 0.1rem 0rem 0.1rem 0rem;
}

.dropdown-toggle::after {
    margin-left: 0.25em;
    vertical-align: 0.25em;
    top: 1.3rem !important;
    width: 10% !important;
    height: 10% !important;
}

.navbar ul li a {
    line-height: 300%;
    font-weight: 500;
    color: var(--white, #fff);
}

li.nav-item.dropdown.show a::after{
    margin-left: 0.25em;
    vertical-align: 0.25em;
    top: 1.3rem !important;
    width: 3% !important;
    height: 3% !important;
}


li.nav-item.dropdown.show div.dropdown-menu.show {
    background-color: transparent;
    margin: 0;
    font-size: 0.8rem;
    color: var(--white, #fff);
    border: 0px solid rgba(0, 0, 0, 0);
    border-radius: 0px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
	padding: 0rem 0rem 1rem 0rem;
}

li.nav-item.dropdown.show div.dropdown-menu.show div.dropdown-item-title {
    display: none;
}

.navbar ul li a.dropdown-item {
    color: var(--white, #fff);
}

.navbar ul li a:hover {
    text-decoration: none;
    color: var(--white, #fff);
}

 .dropdown-menu-user {
    left:-9px !important;
}

.dropdown-menu-user span.icon-user {
    display: none;
}

.container {
    max-width: 100%;
	padding: 0 16px;
}

.container.mt-cab-fixed {
    margin-top: 60px;
}

.card-tabla-proy div.card-body {
    padding: 1rem !important;
}

.footer {
    flex-direction: column;
}

.footer .text-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

h2.title-pag {
    max-width: 220px;
}

.modal-header, .modal-body, .modal-footer.actions {
    padding: 1rem 1.2rem;
}

}



@media (min-width: 576px) and (max-width: 767px) {

  .empusa-container {
    margin-top: 59px;
    margin-bottom: 40px;
    overflow-y: auto;
    height: calc(96vh - 90px);
  }


.horizontal-apli-menu .header .header-inner {
    height: 51px;
	padding: 16px 25px 16px 15px;
}

.header .header-inner .logo-name img {
	height: 20px;
}

.header .header-inner .logo-name span.line-vert {
    height: 20px;
}

.header .header-inner .logo-name div.name-apli {
	font-size: 0.9rem;
}

nav.navbar .navbar-collapse {
	padding: 0.8rem 0rem 2.6rem 0rem;
}

.navbar-expand-xl .navbar-nav .nav-link {
	padding: 0.1rem 0rem 0.1rem 0rem;
}

.dropdown-toggle::after {
    margin-left: 0.25em;
    vertical-align: 0.25em;
    top: 1.3rem !important;
    width: 10% !important;
    height: 10% !important;
}

.navbar ul li a {
    line-height: 300%;
    font-weight: 500;
    color: var(--white, #fff);
}

li.nav-item.dropdown.show a::after{
    margin-left: 0.25em;
    vertical-align: 0.25em;
    top: 1.3rem !important;
    width: 3% !important;
    height: 3% !important;
}


li.nav-item.dropdown.show div.dropdown-menu.show {
    background-color: transparent;
    margin: 0;
    font-size: 0.8rem;
    color: var(--white, #fff);
    border: 0px solid rgba(0, 0, 0, 0);
    border-radius: 0px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
	padding: 0rem 0rem 1rem 0rem;
}

li.nav-item.dropdown.show div.dropdown-menu.show div.dropdown-item-title {
    display: none;
}

.navbar ul li a.dropdown-item {
    color: var(--white, #fff);
}

.navbar ul li a:hover {
    text-decoration: none;
    color: var(--white, #fff);
}

 .dropdown-menu-user {
    left:-9px !important;
}

.container {
    max-width: 100%;
	padding: 0 16px;
}

.container.mt-cab-fixed {
    margin-top: 60px;
}

.card-tabla-proy div.card-body {
    padding: 1rem !important;
}

.footer {
    flex-direction: column;
}

.footer .text-footer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.modal-dialog {
    max-width: 700px !important;
    margin: 1.75rem 1.75rem;
}

}

@media (min-width: 768px) and (max-width: 991px) {
  .empusa-container {
    margin-top: 71px;
    margin-bottom: 40px;
    overflow-y: auto;
    height: calc(96vh - 90px);
  }


nav.navbar .navbar-collapse {
	padding: 0.8rem 0rem 2.6rem 0rem;
}

.navbar-expand-xl .navbar-nav .nav-link {
	padding: 0.1rem 0rem 0.1rem 0rem;
}

.dropdown-toggle::after {
    margin-left: 0.25em;
    vertical-align: 0.25em;
    top: 1.3rem !important;
    width: 10% !important;
    height: 10% !important;
}

.navbar ul li a {
    line-height: 300%;
    font-weight: 500;
    color: var(--white, #fff);
}

li.nav-item.dropdown.show a::after{
    margin-left: 0.25em;
    vertical-align: 0.25em;
    top: 1.3rem !important;
    width: 3% !important;
    height: 3% !important;
}


li.nav-item.dropdown.show div.dropdown-menu.show {
    background-color: transparent;
    margin: 0;
    font-size: 0.8rem;
    color: var(--white, #fff);
    border: 0px solid rgba(0, 0, 0, 0);
    border-radius: 0px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
	padding: 0rem 0rem 1rem 0rem;
}

li.nav-item.dropdown.show div.dropdown-menu.show div.dropdown-item-title {
    display: none;
}

.navbar ul li a.dropdown-item {
    color: var(--white, #fff);
}

.navbar ul li a:hover {
    text-decoration: none;
    color: var(--white, #fff);
}

.container {
    max-width: 100%;
	padding: 0 33px;
}

.container.mt-cab-fixed {
    margin-top: 74px;
}

.modal-dialog {
    max-width: 700px;
    margin: 1.75rem auto;
}

}

@media (min-width: 992px) and (max-width: 1199px) {

  .empusa-container {
    margin-top: 71px;
    margin-bottom: 40px;
    overflow-y: auto;
    height: calc(96vh - 90px);
  }


.horizontal-apli-menu .header .header-inner {
	padding: 20px 42px 20px 33px;
}

.navbar-expand-xl .navbar-toggler {
    display: none !important;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
}

nav.navbar .navbar-collapse {
	padding: 0.8rem 0rem 2.6rem 0rem;
}

.navbar-expand-xl .navbar-nav .nav-link {
	padding: 0.1rem 0rem 0.1rem 0rem;
}

.dropdown-toggle::after {
    margin-left: 0.25em;
    vertical-align: 0.25em;
    top: 1.3rem !important;
    width: 10% !important;
    height: 10% !important;
}

.navbar ul li a {
    line-height: 300%;
    font-weight: 500;
    color: var(--white, #fff);
}

li.nav-item.dropdown.show a::after{
    margin-left: 0.25em;
    vertical-align: 0.25em;
    top: 1.3rem !important;
    width: 3% !important;
    height: 3% !important;
}


li.nav-item.dropdown.show div.dropdown-menu.show {
    background-color: transparent;
    margin: 0;
    font-size: 0.8rem;
    color: var(--white, #fff);
    border: 0px solid rgba(0, 0, 0, 0);
    border-radius: 0px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
	padding: 0rem 0rem 1rem 0rem;
}

li.nav-item.dropdown.show div.dropdown-menu.show div.dropdown-item-title {
    display: none;
}

.navbar ul li a.dropdown-item {
    color: var(--white, #fff);
}

.navbar ul li a:hover {
    text-decoration: none;
    color: var(--white, #fff);
}

.container {
    max-width: 100%;
	padding: 0 33px;
}

.container.mt-cab-fixed {
    margin-top: 74px;
}

}

@media (min-width: 1200px) and (max-width: 1365px) {

.container, .container-sm, .container-md, .container-lg, .container-xl, .container-xxl {
    max-width: 1366px;
}

.navbar-toggler-icon {
      display: none;
}

.navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.6rem !important;
    padding-left: 0.6rem !important;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
}

.navbar ul {
    font-size: 13.5px !important;
}

.header-inner .navbar-toggler {
    display: none;
}
.container {
    max-width: 100%;
	padding: 0 33px;
}

.container.mt-cab-fixed {
    margin-top: 21px;
}
}


@media (min-width: 1366px) and (max-width: 1399px){
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1366px;
  }
.container {
    max-width: 1366px;
	padding: 0 33px;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
}

.navbar ul {
    font-size: 14px;
}

.navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.header-inner .navbar-toggler {
    display: none;
}
}


@media (min-width: 1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 1366px;
  }
.container {
    max-width: 1366px;
	padding: 0 33px;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
}

.navbar ul {
    font-size: 14px;
}

.navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
}

.header-inner .navbar-toggler {
    display: none;
}
}

.container.mt-cab-fixed {
  margin-top: 21px;
}





